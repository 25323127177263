/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'appraisal',
    label: '鑑定機関',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_appraisal'],
    config: {
      // Schema type is enum for SelectMultipleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'pcgs', label: 'PCGS' },
        { key: 'ngc', label: 'NGC' },
        { key: 'anacs', label: 'ANACS' },
        { key: 'icg', label: 'ICG' },
        { key: 'unidentified', label: '未鑑定' },
      ],
    },
  },
  {
    id: 'appraisalNumber',
    label: '鑑定番号',
    type:'KeywordFilter',
    group: 'secondary',
    queryParamNames: ['pub_appraisalNumber'],
    config: {
      schemaType: 'enum'
    },
  },
  {
    id: 'century',
    label: '時代',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_century'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'bc', label: '紀元前' },
        { key: 'century_01', label: '1世紀(0年代)' },
        { key: 'century_02', label: '2世紀(100年代)' },
        { key: 'century_03', label: '3世紀(200年代)' },
        { key: 'century_04', label: '4世紀(300年代)' },
        { key: 'century_05', label: '5世紀(400年代)' },
        { key: 'century_06', label: '6世紀(500年代)' },
        { key: 'century_07', label: '7世紀(600年代)' },
        { key: 'century_08', label: '8世紀(700年代)' },
        { key: 'century_09', label: '9世紀(800年代)' },
        { key: 'century_10', label: '10世紀(900年代)' },
        { key: 'century_11', label: '11世紀(1000年代)' },
        { key: 'century_12', label: '12世紀(1100年代)' },
        { key: 'century_13', label: '13世紀(1200年代)' },
        { key: 'century_14', label: '14世紀(1300年代)' },
        { key: 'century_15', label: '15世紀(1400年代)' },
        { key: 'century_16', label: '16世紀(1500年代)' },
        { key: 'century_17', label: '17世紀(1600年代)' },
        { key: 'century_18', label: '18世紀(1700年代)' },
        { key: 'century_19', label: '19世紀(1800年代)' },
        { key: 'century_20', label: '20世紀(1900年代)' },
        { key: 'century_21', label: '21世紀(2000年代)' },
      ],
    },
  },
  {
    id: 'material',
    label: '素材',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_material'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'gold', label: '金' },
        { key: 'silver', label: '銀' },
        { key: 'copper', label: '銅' },
        { key: 'platinum', label: 'プラチナ' },
      ],
    },
  },
  {
    id: 'price',
    label: '価格',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 100000000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  {
    id: 'country',
    label: '国',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_country'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // Optional modes: 'has_all', 'has_any'
      // Note: this is relevant only for schema type 'multi-enum'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'united_kingdom', label: 'イギリス' },
        { key: 'st_helena', label: 'セントヘレナ' },
        { key: 'alderney', label: 'オルダニー' },
        { key: 'america', label: 'アメリカ' },
        { key: 'mexico', label: 'メキシコ' },
        { key: 'canada', label: 'カナダ' },
        { key: 'france', label: 'フランス' },
        { key: 'germany', label: 'ドイツ' },
        { key: 'spain', label: 'スペイン' },
        { key: 'switzerland', label: 'スイス' },
        { key: 'ancient_rome', label: '古代ローマ' },
        { key: 'ancient_greece', label: '古代ギリシャ' },
        { key: 'italy', label: 'イタリア' },
        { key: 'belgium', label: 'ベルギー' },
        { key: 'austria', label: 'オーストリア' },
        { key: 'malta', label: 'マルタ' },
        { key: 'byzantine_empire', label: 'ビザンツ帝国(東ローマ帝国)' },
        { key: 'holy_roman_rmpire', label: '神聖ローマ帝国' },
        { key: 'russia', label: 'ロシア' },
        { key: 'netherlands', label: 'オランダ' },
        { key: 'europe_other', label: 'ヨーロッパ - その他' },
        { key: 'liberia', label: 'リベリア' },
        { key: 'south_africa', label: '南アフリカ' },
        { key: 'africa_other', label: 'アフリカ - その他' },
        { key: 'brazil', label: 'ブラジル' },
        { key: 'uruguay', label: 'ウルグアイ' },
        { key: 'chile', label: 'チリ' },
        { key: 'peru', label: 'ペルー' },
        { key: 'columbia', label: 'コロンビア' },
        { key: 'argentina', label: 'アルゼンチン' },
        { key: 'south_america_other', label: '南米 - その他' },
        { key: 'papan', label: '日本' },
        { key: 'china', label: '中国' },
        { key: 'india', label: 'インド' },
        { key: 'australia', label: 'オーストラリア' },
        { key: 'taiwan', label: '台湾' },
        { key: 'macau', label: 'マカオ' },
        { key: 'singapore', label: 'シンガポール' },
        { key: 'thailand', label: 'タイ' },
        { key: 'brunei', label: 'ブルネイ' },
        { key: 'asia_oceania_other', label: 'アジア・オセアニア - その他' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: '新しい順' },
    { key: '-createdAt', label: '古い順' },
    { key: '-price', label: '低価格' },
    { key: 'price', label: '高価格' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: '関連商品', longLabel: '関連商品 (キーワード検索)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['appraisal', 'appraisalNumber', 'material', 'century', 'country'],
};
