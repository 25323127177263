// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
  // { code: 'AF', en: 'Afghanistan', fr: 'Afghanistan', es: 'Afganistán', de: 'Afghanistan', ja:'' },
  // { code: 'AX', en: 'Åland Islands', fr: 'Îles Åland', es: 'Islas Áland', de: 'Åland', ja:'' },
  // { code: 'AL', en: 'Albania', fr: 'Albanie', es: 'Albania', de: 'Albanien', ja:'' },
  // { code: 'DZ', en: 'Algeria', fr: 'Algérie', es: 'Argel', de: 'Algerien', ja:'' },
  // { code: 'AS', en: 'American Samoa', fr: 'Samoa américaines', es: 'Samoa Americana', de: 'Amerikanisch-Samoa', ja:'' },
  // { code: 'AD', en: 'Andorra', fr: 'Andorre', es: 'Andorra', de: 'Andorra', ja:'' },
  // { code: 'AO', en: 'Angola', fr: 'Angola', es: 'Angola', de: 'Angola', ja:'' },
  // { code: 'AI', en: 'Anguilla', fr: 'Anguilla', es: 'Anguila', de: 'Anguilla', ja:'' },
  // { code: 'AQ', en: 'Antarctica', fr: 'Antarctique', es: 'Antártida', de: 'Antarktika', ja:'' },
  // { code: 'AG', en: 'Antigua and Barbuda', fr: 'Antigua-et-Barbuda', es: 'Antigua y Barbuda', de: 'Antigua und Barbuda', ja:'' },
  // { code: 'AR', en: 'Argentina', fr: 'Argentine', es: 'Argentina', de: 'Argentinien', ja:'' },
  // { code: 'AM', en: 'Armenia', fr: 'Arménie', es: 'Armenia', de: 'Armenien', ja:'' },
  // { code: 'AW', en: 'Aruba', fr: 'Aruba', es: 'Aruba', de: 'Aruba', ja:'' },
  // { code: 'AT', en: 'Austria', fr: 'Autriche', es: 'Austria', de: 'Österreich', ja:'オーストリア' },
  // { code: 'AU', en: 'Australia', fr: 'Australie', es: 'Australia', de: 'Australien', ja:'オーストラリア' },
  // { code: 'AZ', en: 'Azerbaijan', fr: 'Azerbaïdjan', es: 'Azerbaiyán', de: 'Aserbaidschan', ja:'' },
  // { code: 'BS', en: 'Bahamas', fr: 'Bahamas', es: 'Bahamas', de: 'Bahamas', ja:'' },
  // { code: 'BH', en: 'Bahrain', fr: 'Bahreïn', es: 'Bahréin', de: 'Bahrain', ja:'' },
  // { code: 'BD', en: 'Bangladesh', fr: 'Bangladesh', es: 'Bangladesh', de: 'Bangladesch', ja:'' },
  // { code: 'BB', en: 'Barbados', fr: 'Barbade', es: 'Barbados', de: 'Barbados', ja:'' },
  // { code: 'BY', en: 'Belarus', fr: 'Biélorussie', es: 'Belarús', de: 'Belarus', ja:'' },
  // { code: 'BE', en: 'Belgium', fr: 'Belgique', es: 'Bélgica', de: 'Belgien', ja:'ベルギー' },
  // { code: 'BZ', en: 'Belize', fr: 'Belize', es: 'Belice', de: 'Belize', ja:'' },
  // { code: 'BJ', en: 'Benin', fr: 'Bénin', es: 'Benin', de: 'Benin', ja:'' },
  // { code: 'BM', en: 'Bermuda', fr: 'Bermudes', es: 'Bermudas', de: 'Bermuda', ja:'' },
  // { code: 'BT', en: 'Bhutan', fr: 'Bhoutan', es: 'Bhután', de: 'Bhutan', ja:'' },
  // { code: 'BO', en: 'Bolivia', fr: 'Bolivie', es: 'Bolivia', de: 'Bolivien', ja:'' },
  // { code: 'BQ', en: 'Bonaire, Sint Eustatius and Saba', fr: 'Pays-Bas caribéens', es: 'Caribe Neerlandés', de: 'Bonaire, Sint Eustatius und Saba', ja:'' },
  // { code: 'BA', en: 'Bosnia and Herzegovina', fr: 'Bosnie-Herzégovine', es: 'Bosnia y Herzegovina', de: 'Bosnien und Herzegowina', ja:'' },
  // { code: 'BW', en: 'Botswana', fr: 'Botswana', es: 'Botsuana', de: 'Botswana', ja:'' },
  // { code: 'BV', en: 'Bouvet Island', fr: 'Île Bouvet', es: 'Isla Bouvet', de: 'Bouvetinsel', ja:'' },
  // { code: 'BR', en: 'Brazil', fr: 'Brésil', es: 'Brasil', de: 'Brasilien', ja:'' },
  // { code: 'IO', en: 'British Indian Ocean Territory', fr: 'Territoire britannique de l’Océan Indien', es: 'Territorio Británico del Océano Índico', de: 'Britisches Territorium im Indischen Ozean', ja:'' },
  // { code: 'BN', en: 'Brunei Darussalam', fr: 'Brunei Darussalam', es: 'Brunéi', de: 'Brunei Darussalam', ja:'' },
  // { code: 'BG', en: 'Bulgaria', fr: 'Bulgarie', es: 'Bulgaria', de: 'Bulgarien', ja:'ブルガリア' },
  // { code: 'BF', en: 'Burkina Faso', fr: 'Burkina Faso', es: 'Burkina Faso', de: 'Burkina Faso', ja:'' },
  // { code: 'BI', en: 'Burundi', fr: 'Burundi', es: 'Burundi', de: 'Burundi', ja:'' },
  // { code: 'KH', en: 'Cambodia', fr: 'Cambodge', es: 'Camboya', de: 'Kambodscha', ja:'' },
  // { code: 'CM', en: 'Cameroon', fr: 'Cameroun', es: 'Camerún', de: 'Kamerun', ja:'' },
  // { code: 'CA', en: 'Canada', fr: 'Canada', es: 'Canadá', de: 'Kanada', ja:'カナダ' },
  // { code: 'CV', en: 'Cape Verde', fr: 'Cap-Vert', es: 'Cabo Verde', de: 'Kap Verde', ja:'' },
  // { code: 'KY', en: 'Cayman Islands', fr: 'Iles Cayman', es: 'Islas Caimán', de: 'Kaimaninseln', ja:'' },
  // { code: 'CF', en: 'Central African Republic', fr: 'République centrafricaine', es: 'República Centro-Africana', de: 'Zentralafrikanische Republik', ja:'' },
  // { code: 'TD', en: 'Chad', fr: 'Tchad', es: 'Chad', de: 'Tschad', ja:'' },
  // { code: 'CL', en: 'Chile', fr: 'Chili', es: 'Chile', de: 'Chile', ja:'' },
  // { code: 'CN', en: 'China', fr: 'Chine', es: 'China', de: 'China, Volksrepublik', ja:'' },
  // { code: 'CX', en: 'Christmas Island', fr: 'Île Christmas', es: 'Islas Christmas', de: 'Weihnachtsinsel', ja:'' },
  // { code: 'CC', en: 'Cocos (Keeling) Islands', fr: 'Îles Cocos', es: 'Islas Cocos', de: 'Kokosinseln', ja:'' },
  // { code: 'CO', en: 'Colombia', fr: 'Colombie', es: 'Colombia', de: 'Kolumbien', ja:'' },
  // { code: 'KM', en: 'Comoros', fr: 'Comores', es: 'Comoros', de: 'Komoren', ja:'' },
  // { code: 'CG', en: 'Congo', fr: 'République du Congo', es: 'Congo', de: 'Kongo, Republik', ja:'' },
  // { code: 'CD', en: 'Congo, the Democratic Republic of the', fr: 'République démocratique du Congo', es: 'República democrática del Congo', de: 'Kongo, Demokratische Republik', ja:'' },
  // { code: 'CK', en: 'Cook Islands', fr: 'Îles Cook', es: 'Islas Cook', de: 'Cookinseln', ja:'' },
  // { code: 'CR', en: 'Costa Rica', fr: 'Costa Rica', es: 'Costa Rica', de: 'Costa Rica', ja:'' },
  // { code: 'CI', en: 'Côte d\'Ivoire', fr: 'Côte d’Ivoire', es: 'Costa de Marfil', de: 'Côte d’Ivoire', ja:'' },
  // { code: 'HR', en: 'Croatia', fr: 'Croatie', es: 'Croacia', de: 'Kroatien', ja:'' },
  // { code: 'CU', en: 'Cuba', fr: 'Cuba', es: 'Cuba', de: 'Kuba', ja:'' },
  // { code: 'CW', en: 'Curaçao', fr: 'Curaçao', es: 'Curazao', de: 'Curaçao', ja:'' },
  // { code: 'CY', en: 'Cyprus', fr: 'Chypre', es: 'Chipre', de: 'Zypern', ja:'キプロス' },
  // { code: 'CZ', en: 'Czech Republic', fr: 'République tchèque', es: 'República Checa', de: 'Tschechien', ja:'チェコ' },
  // { code: 'DE', en: 'Germany', fr: 'Allemagne', es: 'Alemania', de: 'Deutschland', ja:'ドイツ' },
  // { code: 'DK', en: 'Denmark', fr: 'Danemark', es: 'Dinamarca', de: 'Dänemark', ja:'デンマーク' },
  // { code: 'DJ', en: 'Djibouti', fr: 'Djibouti', es: 'Yibuti', de: 'Dschibuti', ja:'' },
  // { code: 'DM', en: 'Dominica', fr: 'Dominique', es: 'Domínica', de: 'Dominica', ja:'' },
  // { code: 'DO', en: 'Dominican Republic', fr: 'République dominicaine', es: 'República Dominicana', de: 'Dominikanische Republik', ja:'' },
  // // { code: 'EC', en: 'Ecuador', fr: 'Équateur', es: 'Ecuador', de: 'Ecuador', ja:'' },
  // // { code: 'EG', en: 'Egypt', fr: 'Égypte', es: 'Egipto', de: 'Ägypten', ja:'' },
  // { code: 'SV', en: 'El Salvador', fr: 'Salvador', es: 'El Salvador', de: 'El Salvador', ja:'' },
  // { code: 'GQ', en: 'Equatorial Guinea', fr: 'Guinée équatoriale', es: 'Guinea Ecuatorial', de: 'Äquatorialguinea', ja:'' },
  // { code: 'ER', en: 'Eritrea', fr: 'Érythrée', es: 'Eritrea', de: 'Eritrea', ja:'' },
  // { code: 'EE', en: 'Estonia', fr: 'Estonie', es: 'Estonia', de: 'Estland', ja:'エストニア' },
  // { code: 'ET', en: 'Ethiopia', fr: 'Éthiopie', es: 'Etiopía', de: 'Äthiopien', ja:'' },
  // { code: 'FK', en: 'Falkland Islands (Malvinas)', fr: 'Îles Falkland', es: 'Islas Malvinas', de: 'Falklandinseln', ja:'' },
  // { code: 'FO', en: 'Faroe Islands', fr: 'Îles Féroé', es: 'Islas Faroe', de: 'Färöer', ja:'' },
  // { code: 'FJ', en: 'Fiji', fr: 'Fidji', es: 'Fiji', de: 'Fidschi', ja:'' },
  // { code: 'FI', en: 'Finland', fr: 'Finlande', es: 'Finlandia', de: 'Finnland', ja:'フィンランド' },
  // { code: 'FR', en: 'France', fr: 'France', es: 'Francia', de: 'Frankreich', ja:'フランス' },
  // { code: 'GF', en: 'French Guiana', fr: 'Guyane française', es: 'Guayana Francesa', de: 'Französisch-Guayana', ja:'' },
  // { code: 'PF', en: 'French Polynesia', fr: 'Polynésie française', es: 'Polinesia Francesa', de: 'Französisch-Polynesien', ja:'ポルトガル' },
  // { code: 'TF', en: 'French Southern Territories', fr: 'Terres australes et antarctiques françaises', es: 'Territorios Australes Franceses', de: 'Französische Süd- und Antarktisgebiete', ja:'' },
  // { code: 'GA', en: 'Gabon', fr: 'Gabon', es: 'Gabón', de: 'Gabun', ja:'' },
  // { code: 'GM', en: 'Gambia', fr: 'Gambie', es: 'Gambia', de: 'Gambia', ja:'' },
  // { code: 'GE', en: 'Georgia', fr: 'Géorgie', es: 'Georgia', de: 'Georgien', ja:'' },
  // { code: 'GH', en: 'Ghana', fr: 'Ghana', es: 'Ghana', de: 'Ghana', ja:'' },
  // { code: 'GI', en: 'Gibraltar', fr: 'Gibraltar', es: 'Gibraltar', de: 'Gibraltar', ja:'' },
  // { code: 'GR', en: 'Greece', fr: 'Grèce', es: 'Grecia', de: 'Griechenland', ja:'ギリシャ' },
  // { code: 'GL', en: 'Greenland', fr: 'Groenland', es: 'Groenlandia', de: 'Grönland', ja:'' },
  // { code: 'GD', en: 'Grenada', fr: 'Grenade', es: 'Granada', de: 'Grenada', ja:'' },
  // { code: 'GP', en: 'Guadeloupe', fr: 'Guadeloupe', es: 'Guadalupe', de: 'Guadeloupe', ja:'' },
  // { code: 'GU', en: 'Guam', fr: 'Guam', es: 'Guam', de: 'Guam', ja:'' },
  // { code: 'GT', en: 'Guatemala', fr: 'Guatemala', es: 'Guatemala', de: 'Guatemala', ja:'' },
  // { code: 'GG', en: 'Guernsey', fr: 'Guernesey', es: 'Guernsey', de: 'Guernsey', ja:'' },
  // { code: 'GN', en: 'Guinea', fr: 'Guinée', es: 'Guinea', de: 'Guinea', ja:'' },
  // { code: 'GW', en: 'Guinea-Bissau', fr: 'Guinée-Bissau', es: 'Guinea-Bissau', de: 'Guinea-Bissau', ja:'' },
  // { code: 'GY', en: 'Guyana', fr: 'Guyane', es: 'Guayana', de: 'Guyana', ja:'' },
  // { code: 'HT', en: 'Haiti', fr: 'Haïti', es: 'Haití', de: 'Haiti', ja:'' },
  // { code: 'HM', en: 'Heard Island and McDonald Islands', fr: 'Îles Heard-et-MacDonald', es: 'Islas Heard y McDonald', de: 'Heard und McDonaldinseln', ja:'' },
  // { code: 'VA', en: 'Holy See (Vatican City State)', fr: 'Saint-Siège (Vatican)', es: 'Ciudad del Vaticano', de: 'Vatikanstadt', ja:'' },
  // { code: 'HN', en: 'Honduras', fr: 'Honduras', es: 'Honduras', de: 'Honduras', ja:'' },
  // { code: 'HK', en: 'Hong Kong', fr: 'Hong Kong', es: 'Hong Kong', de: 'Hongkong', ja:'香港' },
  // { code: 'HU', en: 'Hungary', fr: 'Hongrie', es: 'Hungría', de: 'Ungarn', ja:'' },
  // { code: 'IS', en: 'Iceland', fr: 'Islande', es: 'Islandia', de: 'Island', ja:'' },
  // { code: 'IN', en: 'India', fr: 'Inde', es: 'India', de: 'Indien', ja:'' },
  // { code: 'ID', en: 'Indonesia', fr: 'Indonésie', es: 'Indonesia', de: 'Indonesien', ja:'' },
  // { code: 'IR', en: 'Iran, Islamic Republic of', fr: 'Iran', es: 'Irán', de: 'Iran, Islamische Republik', ja:'' },
  // { code: 'IQ', en: 'Iraq', fr: 'Irak', es: 'Irak', de: 'Irak', ja:'' },
  // { code: 'IE', en: 'Ireland', fr: 'Irlande', es: 'Irlanda', de: 'Irland', ja:'アイルランド' },
  // { code: 'IM', en: 'Isle of Man', fr: 'Ile de Man', es: 'Isla de Man', de: 'Insel Man', ja:'' },
  // { code: 'IL', en: 'Israel', fr: 'Israël', es: 'Israel', de: 'Israel', ja:'' },
  // { code: 'IT', en: 'Italy', fr: 'Italie', es: 'Italia', de: 'Italien', ja:'イタリア' },
  // { code: 'JM', en: 'Jamaica', fr: 'Jamaïque', es: 'Jamaica', de: 'Jamaika', ja:'' },
  { code: 'JP', en: 'Japan', fr: 'Japon', es: 'Japón', de: 'Japan', ja:'日本' },
  // { code: 'JE', en: 'Jersey', fr: 'Jersey', es: 'Jersey', de: 'Jersey (Kanalinsel)', ja:'' },
  // { code: 'JO', en: 'Jordan', fr: 'Jordanie', es: 'Jordania', de: 'Jordanien', ja:'' },
  // { code: 'KZ', en: 'Kazakhstan', fr: 'Kazakhstan', es: 'Kazajstán', de: 'Kasachstan', ja:'' },
  // { code: 'KE', en: 'Kenya', fr: 'Kenya', es: 'Kenia', de: 'Kenia', ja:'' },
  // { code: 'KI', en: 'Kiribati', fr: 'Kiribati', es: 'Kiribati', de: 'Kiribati', ja:'' },
  // { code: 'KP', en: 'Korea, Democratic People\'s Republic of', fr: 'Corée du Nord', es: 'Corea del Norte', de: 'Korea, Demokratische Volksrepublik (Nordkorea)', ja:'' },
  // { code: 'KR', en: 'Korea, Republic of', fr: 'Corée du Sud', es: 'Corea del Sur', de: 'Korea, Republik (Südkorea)', ja:'' },
  // { code: 'KW', en: 'Kuwait', fr: 'Koweït', es: 'Kuwait', de: 'Kuwait', ja:'' },
  // { code: 'KG', en: 'Kyrgyzstan', fr: 'Kirghizistan', es: 'Kirguistán', de: 'Kirgisistan', ja:'' },
  // { code: 'LA', en: 'Laos', fr: 'Laos', es: 'Laos', de: 'Laos', ja:'' },
  // { code: 'LV', en: 'Latvia', fr: 'Lettonie', es: 'Letonia', de: 'Lettland', ja:'ラトビア' },
  // { code: 'LB', en: 'Lebanon', fr: 'Liban', es: 'Líbano', de: 'Libanon', ja:'' },
  // { code: 'LS', en: 'Lesotho', fr: 'Lesotho', es: 'Lesotho', de: 'Lesotho', ja:'' },
  // { code: 'LR', en: 'Liberia', fr: 'Libéria', es: 'Liberia', de: 'Liberia', ja:'' },
  // { code: 'LY', en: 'Libya', fr: 'Libye', es: 'Libia', de: 'Libyen', ja:'' },
  // { code: 'LI', en: 'Liechtenstein', fr: 'Liechtenstein', es: 'Liechtenstein', de: 'Liechtenstein', ja:'' },
  // { code: 'LT', en: 'Lithuania', fr: 'Lituanie', es: 'Lituania', de: 'Litauen', ja:'リトアニア' },
  // { code: 'LU', en: 'Luxembourg', fr: 'Luxembourg', es: 'Luxemburgo', de: 'Luxemburg', ja:'ルクセンブルク' },
  // { code: 'MO', en: 'Macao', fr: 'Macao', es: 'Macao', de: 'Macau', ja:'' },
  // { code: 'MK', en: 'North Macedonia', fr: 'Macédoine du Nord', es: 'Macedonia del Norte', de: 'Nordmazedonien', ja:'' },
  // { code: 'MG', en: 'Madagascar', fr: 'Madagascar', es: 'Madagascar', de: 'Madagaskar', ja:'' },
  // { code: 'MW', en: 'Malawi', fr: 'Malawi', es: 'Malawi', de: 'Malawi', ja:'' },
  // { code: 'MY', en: 'Malaysia', fr: 'Malaisie', es: 'Malasia', de: 'Malaysia', ja:'' },
  // { code: 'MV', en: 'Maldives', fr: 'Maldives', es: 'Maldivas', de: 'Malediven', ja:'' },
  // { code: 'ML', en: 'Mali', fr: 'Mali', es: 'Mali', de: 'Mali', ja:'' },
  // { code: 'MT', en: 'Malta', fr: 'Malte', es: 'Malta', de: 'Malta', ja:'マルタ' },
  // { code: 'MH', en: 'Marshall Islands', fr: 'Îles Marshall', es: 'Islas Marshall', de: 'Marshallinseln', ja:'' },
  // { code: 'MQ', en: 'Martinique', fr: 'Martinique', es: 'Martinica', de: 'Martinique', ja:'' },
  // { code: 'MR', en: 'Mauritania', fr: 'Mauritanie', es: 'Mauritania', de: 'Mauretanien', ja:'' },
  // { code: 'MU', en: 'Mauritius', fr: 'Maurice', es: 'Mauricio', de: 'Mauritius', ja:'' },
  // { code: 'YT', en: 'Mayotte', fr: 'Mayotte', es: 'Mayotte', de: 'Mayotte', ja:'' },
  // { code: 'MX', en: 'Mexico', fr: 'Mexique', es: 'México', de: 'Mexiko', ja:'メキシコ' },
  // { code: 'FM', en: 'Micronesia, Federated States of', fr: 'Micronésie', es: 'Micronesia', de: 'Mikronesien', ja:'' },
  // { code: 'MD', en: 'Moldova', fr: 'Moldavie', es: 'Moldova', de: 'Moldawien', ja:'' },
  // { code: 'MC', en: 'Monaco', fr: 'Monaco', es: 'Mónaco', de: 'Monaco', ja:'' },
  // { code: 'MN', en: 'Mongolia', fr: 'Mongolie', es: 'Mongolia', de: 'Mongolei', ja:'' },
  // { code: 'ME', en: 'Montenegro', fr: 'Monténégro', es: 'Montenegro', de: 'Montenegro', ja:'' },
  // { code: 'MS', en: 'Montserrat', fr: 'Montserrat', es: 'Montserrat', de: 'Montserrat', ja:'' },
  // { code: 'MA', en: 'Morocco', fr: 'Maroc', es: 'Marruecos', de: 'Marokko', ja:'' },
  // { code: 'MZ', en: 'Mozambique', fr: 'Mozambique', es: 'Mozambique', de: 'Mosambik', ja:'' },
  // { code: 'MM', en: 'Myanmar', fr: 'Myanmar', es: 'Myanmar', de: 'Myanmar', ja:'' },
  // { code: 'NA', en: 'Namibia', fr: 'Namibie', es: 'Namibia', de: 'Namibia', ja:'' },
  // { code: 'NR', en: 'Nauru', fr: 'Nauru', es: 'Nauru', de: 'Nauru', ja:'' },
  // { code: 'NP', en: 'Nepal', fr: 'Népal', es: 'Nepal', de: 'Nepal', ja:'' },
  // { code: 'NL', en: 'Netherlands', fr: 'Pays-Bas', es: 'Países Bajos', de: 'Niederlande', ja:'オランダ' },
  // { code: 'NC', en: 'New Caledonia', fr: 'Nouvelle-Calédonie', es: 'Nueva Caledonia', de: 'Neukaledonien', ja:'' },
  // { code: 'NZ', en: 'New Zealand', fr: 'Nouvelle-Zélande', es: 'Nueva Zelanda', de: 'Neuseeland', ja:'ニュージーランド' },
  // { code: 'NI', en: 'Nicaragua', fr: 'Nicaragua', es: 'Nicaragua', de: 'Nicaragua', ja:'' },
  // { code: 'NE', en: 'Niger', fr: 'Niger', es: 'Níger', de: 'Niger', ja:'' },
  // { code: 'NG', en: 'Nigeria', fr: 'Nigeria', es: 'Nigeria', de: 'Nigeria', ja:'' },
  // { code: 'NU', en: 'Niue', fr: 'Niue', es: 'Niue', de: 'Niue', ja:'' },
  // { code: 'NF', en: 'Norfolk Island', fr: 'Île Norfolk', es: 'Islas Norkfolk', de: 'Norfolkinsel', ja:'' },
  // { code: 'MP', en: 'Northern Mariana Islands', fr: 'Îles Mariannes du Nord', es: 'Islas Marianas del Norte', de: 'Nördliche Marianen', ja:'' },
  // { code: 'NO', en: 'Norway', fr: 'Norvège', es: 'Noruega', de: 'Norwegen', ja:'ノルウェー' },
  // { code: 'OM', en: 'Oman', fr: 'Oman', es: 'Omán', de: 'Oman', ja:'' },
  // { code: 'PK', en: 'Pakistan', fr: 'Pakistan', es: 'Pakistán', de: 'Pakistan', ja:'' },
  // { code: 'PW', en: 'Palau', fr: 'Palau', es: 'Islas Palaos', de: 'Palau', ja:'' },
  // { code: 'PS', en: 'Palestine, State of', fr: 'Palestine', es: 'Palestina', de: 'Staat Palästina', ja:'' },
  // { code: 'PA', en: 'Panama', fr: 'Panama', es: 'Panamá', de: 'Panama', ja:'' },
  // { code: 'PG', en: 'Papua New Guinea', fr: 'Papouasie-Nouvelle-Guinée', es: 'Papúa Nueva Guinea', de: 'Papua-Neuguinea', ja:'' },
  // { code: 'PY', en: 'Paraguay', fr: 'Paraguay', es: 'Paraguay', de: 'Paraguay', ja:'' },
  // { code: 'PE', en: 'Peru', fr: 'Pérou', es: 'Perú', de: 'Peru', ja:'' },
  // { code: 'PH', en: 'Philippines', fr: 'Philippines', es: 'Filipinas', de: 'Philippinen', ja:'' },
  // { code: 'PN', en: 'Pitcairn', fr: 'Pitcairn', es: 'Islas Pitcairn', de: 'Pitcairninseln', ja:'' },
  // { code: 'PL', en: 'Poland', fr: 'Pologne', es: 'Polonia', de: 'Polen', ja:'ポーランド' },
  // { code: 'PT', en: 'Portugal', fr: 'Portugal', es: 'Portugal', de: 'Portugal', ja:'ポルトガル' },
  // { code: 'PR', en: 'Puerto Rico', fr: 'Puerto Rico', es: 'Puerto Rico', de: 'Puerto Rico', ja:'' },
  // { code: 'QA', en: 'Qatar', fr: 'Qatar', es: 'Qatar', de: 'Katar', ja:'' },
  // { code: 'RE', en: 'Réunion', fr: 'Réunion', es: 'Reunión', de: 'Réunion', ja:'' },
  // { code: 'RO', en: 'Romania', fr: 'Roumanie', es: 'Rumanía', de: 'Rumänien', ja:'ルーマニア' },
  // { code: 'RU', en: 'Russian Federation', fr: 'Russie', es: 'Rusia', de: 'Russische Föderation', ja:'' },
  // { code: 'RW', en: 'Rwanda', fr: 'Rwanda', es: 'Ruanda', de: 'Ruanda', ja:'' },
  // { code: 'BL', en: 'Saint Barthélemy', fr: 'Saint-Barthélemy', es: 'San Bartolomé', de: 'Saint-Barthélemy', ja:'' },
  // { code: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha', fr: 'Sainte-Hélène', es: 'Santa Elena', de: 'St. Helena', ja:'' },
  // { code: 'KN', en: 'Saint Kitts and Nevis', fr: 'Saint-Kitts-et-Nevis', es: 'San Cristóbal y Nieves', de: 'St. Kitts und Nevis', ja:'' },
  // { code: 'LC', en: 'Saint Lucia', fr: 'Sainte-Lucie', es: 'Santa Lucía', de: 'St. Lucia', ja:'' },
  // { code: 'MF', en: 'Saint Martin (French part)', fr: 'Saint-Martin (partie française)', es: 'San Martín (parte francesa)', de: 'Saint-Martin (franz. Teil)', ja:'' },
  // { code: 'PM', en: 'Saint Pierre and Miquelon', fr: 'Saint-Pierre-et-Miquelon', es: 'San Pedro y Miquelón', de: 'Saint-Pierre und Miquelon', ja:'' },
  // { code: 'VC', en: 'Saint Vincent and the Grenadines', fr: 'Saint-Vincent-et-les Grenadines', es: 'San Vicente y las Granadinas', de: 'St. Vincent und die Grenadinen', ja:'' },
  // { code: 'WS', en: 'Samoa', fr: 'Samoa', es: 'Samoa', de: 'Samoa', ja:'' },
  // { code: 'SM', en: 'San Marino', fr: 'Saint-Marin', es: 'San Marino', de: 'San Marino', ja:'' },
  // { code: 'ST', en: 'Sao Tome and Principe', fr: 'Sao Tomé-et-Principe', es: 'Santo Tomé y Príncipe', de: 'São Tomé und Príncipe', ja:'' },
  // { code: 'SA', en: 'Saudi Arabia', fr: 'Arabie Saoudite', es: 'Arabia Saudita', de: 'Saudi-Arabien', ja:'' },
  // { code: 'SN', en: 'Senegal', fr: 'Sénégal', es: 'Senegal', de: 'Senegal', ja:'' },
  // { code: 'RS', en: 'Serbia', fr: 'Serbie', es: 'Serbia y Montenegro', de: 'Serbien', ja:'' },
  // { code: 'SC', en: 'Seychelles', fr: 'Seychelles', es: 'Seychelles', de: 'Seychellen', ja:'' },
  // { code: 'SL', en: 'Sierra Leone', fr: 'Sierra Leone', es: 'Sierra Leona', de: 'Sierra Leone', ja:'' },
  // { code: 'SG', en: 'Singapore', fr: 'Singapour', es: 'Singapur', de: 'Singapur', ja:'シンガポール' },
  // { code: 'SX', en: 'Sint Maarten (Dutch part)', fr: 'Saint-Martin (partie néerlandaise)', es: 'San Martín (parte neerlandesa)', de: 'Sint Maarten (niederl. Teil)', ja:'' },
  // { code: 'SK', en: 'Slovakia', fr: 'Slovaquie', es: 'Eslovaquia', de: 'Slowakei', ja:'スロヴァキア' },
  // { code: 'SI', en: 'Slovenia', fr: 'Slovénie', es: 'Eslovenia', de: 'Slowenien', ja:'スロヴェニア' },
  // { code: 'SB', en: 'Solomon Islands', fr: 'Îles Salomon', es: 'Islas Solomón', de: 'Salomonen', ja:'' },
  // { code: 'SO', en: 'Somalia', fr: 'Somalie', es: 'Somalia', de: 'Somalia', ja:'' },
  // { code: 'ZA', en: 'South Africa', fr: 'Afrique du Sud', es: 'Sudáfrica', de: 'Südafrika', ja:'' },
  // { code: 'GS', en: 'South Georgia and the South Sandwich Islands', fr: 'Géorgie du Sud et les îles Sandwich du Sud', es: 'Georgia del Sur e Islas Sandwich deSur', de: 'Südgeorgien und die Südlichen Sandwichinseln', ja:'' },
  // { code: 'SS', en: 'South Sudan', fr: 'Sud-Soudan', es: 'Sudán del Sur', de: 'Südsudan', ja:'' },
  // { code: 'ES', en: 'Spain', fr: 'Espagne', es: 'España', de: 'Spanien', ja:'' },
  // { code: 'LK', en: 'Sri Lanka', fr: 'Sri Lanka', es: 'Sri Lanka', de: 'Sri Lanka', ja:'' },
  // { code: 'SD', en: 'Sudan', fr: 'Soudan', es: 'Sudán', de: 'Sudan', ja:'' },
  // { code: 'SR', en: 'Suriname', fr: 'Suriname', es: 'Surinam', de: 'Suriname', ja:'' },
  // { code: 'SJ', en: 'Svalbard and Jan Mayen', fr: 'Svalbard et Jan Mayen', es: 'Islas Svalbard y Jan Mayen', de: 'Svalbard und Jan Mayen', ja:'' },
  // { code: 'SZ', en: 'Swaziland', fr: 'Eswatini', es: 'Suazilandia', de: 'Swasiland', ja:'' },
  // { code: 'SE', en: 'Sweden', fr: 'Suède', es: 'Suecia', de: 'Schweden', ja:'スウェーデン' },
  // { code: 'CH', en: 'Switzerland', fr: 'Suisse', es: 'Suiza', de: 'Schweiz', ja:'' },
  // { code: 'SY', en: 'Syrian Arab Republic', fr: 'Syrie', es: 'Siria', de: 'Syrien, Arabische Republik', ja:'' },
  // { code: 'TW', en: 'Taiwan', fr: 'Taiwan', es: 'Taiwán', de: 'Taiwan', ja:'' },
  // { code: 'TJ', en: 'Tajikistan', fr: 'Tadjikistan', es: 'Tayikistán', de: 'Tadschikistan', ja:'' },
  // { code: 'TZ', en: 'Tanzania', fr: 'Tanzanie', es: 'Tanzania', de: 'Tansania', ja:'' },
  // { code: 'TH', en: 'Thailand', fr: 'Thaïlande', es: 'Tailandia', de: 'Thailand', ja:'' },
  // { code: 'TL', en: 'Timor-Leste', fr: 'Timor-Leste', es: 'Timor-Leste', de: 'Osttimor ', ja:'' },
  // { code: 'TG', en: 'Togo', fr: 'Togo', es: 'Togo', de: 'Togo', ja:'' },
  // { code: 'TK', en: 'Tokelau', fr: 'Tokelau', es: 'Tokelau', de: 'Tokelau', ja:'' },
  // { code: 'TO', en: 'Tonga', fr: 'Tonga', es: 'Tonga', de: 'Tonga', ja:'' },
  // { code: 'TT', en: 'Trinidad and Tobago', fr: 'Trinité-et-Tobago', es: 'Trinidad y Tobago', de: 'Trinidad und Tobago', ja:'' },
  // { code: 'TN', en: 'Tunisia', fr: 'Tunisie', es: 'Túnez', de: 'Tunesien', ja:'' },
  // { code: 'TR', en: 'Turkey', fr: 'Turquie', es: 'Turquía', de: 'Türkei', ja:'' },
  // { code: 'TM', en: 'Turkmenistan', fr: 'Turkménistan', es: 'Turkmenistán', de: 'Turkmenistan', ja:'' },
  // { code: 'TC', en: 'Turks and Caicos Islands', fr: 'Îles Turques-et-Caïques', es: 'Islas Turcas y Caicos', de: 'Turks- und Caicosinseln', ja:'' },
  // { code: 'TV', en: 'Tuvalu', fr: 'Tuvalu', es: 'Tuvalu', de: 'Tuvalu', ja:'' },
  // { code: 'UG', en: 'Uganda', fr: 'Ouganda', es: 'Uganda', de: 'Uganda', ja:'' },
  // { code: 'UA', en: 'Ukraine', fr: 'Ukraine', es: 'Ucrania', de: 'Ukraine', ja:'' },
  // { code: 'AE', en: 'United Arab Emirates', fr: 'Émirats Arabes Unis', es: 'Emiratos Árabes Unidos', de: 'Vereinigte Arabische Emirate', ja:'' },
  // { code: 'GB', en: 'United Kingdom', fr: 'Royaume-Uni', es: 'Reino Unido', de: 'Vereinigtes Königreich Großbritannien und Nordirland', ja:'' },
  // { code: 'US', en: 'United States', fr: 'États-Unis', es: 'Estados Unidos de América', de: 'Vereinigte Staaten von Amerika', ja:'アメリカ' },
  // { code: 'UM', en: 'United States Minor Outlying Islands', fr: 'Îles mineures éloignées des États-Unis', es: 'Islas Ultramarinas Menores de Estados Unidos', de: 'United States Minor Outlying Islands', ja:'' },
  // { code: 'UY', en: 'Uruguay', fr: 'Uruguay', es: 'Uruguay', de: 'Uruguay', ja:'' },
  // { code: 'UZ', en: 'Uzbekistan', fr: 'Ouzbékistan', es: 'Uzbekistán', de: 'Usbekistan', ja:'' },
  // { code: 'VU', en: 'Vanuatu', fr: 'Vanuatu', es: 'Vanuatu', de: 'Vanuatu', ja:'' },
  // { code: 'VE', en: 'Venezuela', fr: 'Venezuela', es: 'Venezuela', de: 'Venezuela', ja:'' },
  // { code: 'VN', en: 'Viet Nam', fr: 'Viêt Nam', es: 'Vietnam', de: 'Vietnam', ja:'' },
  // { code: 'VG', en: 'Virgin Islands, British', fr: 'British Virgin Islands', es: 'Islas Vírgenes Británicas', de: 'Britische Jungferninseln', ja:'' },
  // { code: 'VI', en: 'Virgin Islands, U.S.', fr: 'Îles Vierges américaines', es: 'Islas Vírgenes de los Estados Unidos de América', de: 'Amerikanische Jungferninseln', ja:'' },
  // { code: 'WF', en: 'Wallis and Futuna', fr: 'Wallis-et-Futuna', es: 'Wallis y Futuna', de: 'Wallis und Futuna', ja:'' },
  // { code: 'EH', en: 'Western Sahara', fr: 'Sahara occidental', es: 'Sahara Occidental', de: 'Westsahara', ja:'' },
  // { code: 'YE', en: 'Yemen', fr: 'Yémen', es: 'Yemen', de: 'Jemen', ja:'' },
  // { code: 'ZM', en: 'Zambia', fr: 'Zambie', es: 'Zambia', de: 'Sambia', ja:'' },
  // { code: 'ZW', en: 'Zimbabwe', fr: 'Zimbabwe', es: 'Zimbabue', de: 'Simbabwe', ja:'' },
];

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map(c => {
    const countryName = c[lang] ? c[lang] : c['ja'];
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
