import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        本規約は、株式会社ACM（以下「弊社」といいます。）が運営するインターネットサービス「ANTIQUE COIN MARKET」において提供するアンティークコインオークションに付随する各種サービス（以下「本サービス」といいます。）の利用の諸条件を定めるものです。
      </p>
      <p>
        弊社が定める手順にしたがい、本サービスを利用するために会員登録を完了されたお客様は、会員登録完了時点で本規約に同意頂いたものとさせて頂き、本サービスを利用する際、本規約が適用となります。
      </p>

      <h3>第1条　定義</h3>
      <ul className={css.contentNumberlist}>
        <li>
          「本規約」とは、弊社が定める本利用規約、プライバシーポリシー及びガイドラインをいい、これらはすべて本規約を構成するものとなります。
        </li>
        <li>
          「会員」とは、本サービスを利用するために会員登録を完了されたお客様をいいます。なお、会員登録の際、①ご登録頂いたメールアドレスまたは②弊社が指定し連携を認めるソーシャルネットワークサービスアカウントが「ログインID」となります。
        </li>
        <li>
          「アカウント情報」とは、会員に関する情報であって、年齢その他個人に関する属性情報、ログインID及びパスワード等をいいます。なお、パスワードはアルファベットと数字と記号を組合わせたものを会員が任意に設定するものになります。
        </li>
        <li>「出品者」とは、商品等の情報を掲載（以下「出品」といいます。）した会員をいいます。</li>
        <li>
          「販売者」とは、出品者のうち、現実に商品を販売（以下「販売」といいます。）した会員をいいます。
        </li>
        <li>
          「購入者」とは、購入希望金額を申し込み（以下「購入」といいます。）した会員をいいます。
        </li>
        <li>
          「個人情報」とは、個人情報の保護に関する法律（平成 15 年法律第
          57号。その後の改正を含みます。）第２条において定める「個人情報」を指すものとし、会員が入力した氏名、住所、生年月日、性別、職業、電話番号、アカウント情報（年齢その他個人に関する属性情報の他、電子メールアドレス、パスワード等を含みます。）、クレジットカード情報、利用履歴等で、かつ特定の個人を識別できる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することとなるものを含みます。）をいいます。
        </li>
        <li>「商品」とは、出品者が本サービスにおいて出品するコインをいいます。</li>
        <li>
          「取引」とは、本サービスを利用して出品者と購入者との間で成立したコインの売買契約に基づく取引をいいます。
        </li>
        <li>
          「取引の成立」は、出品者の求める代金にて、購入者が購入意思を送信した時をいい、この時に売買契約は成立いたします。ただし、出品者が弊社に商品を送付せず、または商品が偽造品等により商品自体に問題があった場合には取引は成立しなかったものとして扱います。
        </li>
        <li>
          「商品代金」とは、本サービスにおいて商品が購入された場合の、出品者と購入者との間の売買契約における当該商品の売買価格をいいます。
        </li>
        <li>
          「手数料」とは、本サービスの利用に関連して、弊社が会員から徴収する料金（出品手数料、販売手数料、購入手数料その他名称の如何を問わないものとします。）をいいます。
        </li>
        <li>
          「チャット」とは、本サービスにおいて、弊社が提供する会員間において対話可能なコミュニケーション機能をいいます。
        </li>
        <li>
          「決済事業者」[AS1]とは、取引の決裁を行う際に利用することが可能な、弊社が指定するプラットフォーム・アプリまたは決済事業者のことをいいます。
        </li>
      </ul>

      <h3>第2条　各種手数料</h3>
      <ul className={css.contentNumberlist}>
        <li>
          ＜出品者＞出品手数料、送料について
          <p>
            原則として、弊社への出品手数料は発生せず、出品に際しご負担頂くのは、出品者が商品を弊社まで送付する際の送料になります。
          </p>
        </li>
        <li>
          ＜販売者＞販売手数料、振込手数料について
          <p>
            出品者が負担する販売手数料は、販売価格の5％＋消費税 の金額になります。
            ただし、最低手数料は2,000円＋消費税
            とし、課税前の販売手数料が2,000円を下回る場合（販売価格が400,000円未満の場合）は、販売手数料は一律2,000円＋消費税
            とします。 また、商品代金支払いの銀行振込時の手数料は出品者の負担とします。
          </p>
        </li>
        <li>
          ＜購入者＞購入手数料、振込手数料、送料について
          <p>
            購入者が負担する購入手数料は、販売価格の5％＋消費税 の金額になります。
            ただし、最低手数料は2,000円＋消費税
            とし、課税前の購入手数料が2,000円を下回る場合（販売価格が400,000円未満の場合）は、購入手数料は一律2,000円＋消費税
            とします。
            また、弊社との授受等の際に発生する商品の送料（日本国内一律金1,000円）及び商品代金支払いの銀行振込手数料・現金書留等の諸経費は購入者の負担とします。
            購入申込後、商品代金及び購入手数料の合計金額を３営業日以内に入金をお願い致します。
          </p>
        </li>
      </ul>

      <h3>第3条　商品の発送について</h3>
      <ul className={css.contentNumberlist}>
        <li>
          ＜出品者＞出品した商品の発送について
          <p>出品者は、取引成立後３営業日以内に弊社に対し、商品を発送しなければなりません。</p>
        </li>
        <li>
          ＜弊社＞購入頂いた商品の発送について
          <p>
            出品者から商品が弊社に到達し、かつ購入者から弊社に対する入金確認後、３営業日以内に購入者宛に商品を発送致します。
          </p>
        </li>
        <li>
          ＜出品者＞出品商品の返却送料について
          <p>
            出品者が弊社に商品を発送後、商品の不備等により取引が終了した場合、弊社から商品を出品者に送付する際の商品の発送は、弊社の費用負担において、取引の終了から３営業日以内に発送させて頂きます。ただし、偽造品の送付等商品の不備等（以下、「商品の不備等」といいます。）が出品者の故意であると弊社が判断した場合は出品者の費用負担とすることがあります。なお、出品者において弊社が発送した商品を受領しないとき、出品者は、弊社が商品発送した日から半年が経過した時点において、商品の所有権を放棄したものとみなし、弊社が商品の所有権を取得するものとします。
          </p>
        </li>
        <li>
          ＜購入者＞購入商品の返品・返金について
          <p>
            取引成立後は購入者による返品は原則として受け付けておりません。ただし、出品者が弊社に商品を送付せず、または商品の不備等により問題があった場合には取引は成立せず、その場合に限り返金いたします。また、郵送中の破損や汚れに関しましては、弊社に帰責性はなく、配送担当事業者へご連絡ください。
          </p>
          <p>
            なお、購入者の購入代金の未入金等購入者の責任で取引が解約・終了した場合、商品の返却送料等弊社に生じた一切の損害は購入者負担となります。
          </p>
        </li>
      </ul>

      <h3>第4条　本サービスの内容及び弊社の役割</h3>
      <ul className={css.contentNumberlist}>
        <li>
          サービス内容
          <p>
            弊社が提供する本サービスは販売代行サービスになります。会員間の商品の売買契約はあくまで会員のみが契約当事者であり、出品する商品、取引の内容、契約の成否、出品者及び購入者の運送保証等契約に関わる一切の事項に関しては、すべて当事者である会員の自己責任とし、弊社は、出品者からお預りする商品の「真贋の保証」、購入者からの商品代金の入金確認、授受サポートのみ行うものとします。
          </p>
          <p>
            弊社は、本規約中に別段の定めがある部分的サポートを除き、会員間の取引に関し、売買契約の取消し、解約、解除等の意思決定、返品、返金、運送保証など取引の遂行には一切関与しません。
          </p>
          <p>
            本サービスにおける取引の流れ（但し、（４）ないし（６）については順不同となっております）並びに各場面におけるルール及び各会員の遵守事項は以下のとおりとなります。
          </p>
          <p>（１）出品者が商品を登録・出品</p>
          <div className={css.attentionlist}>
            <p>
              ※弊社において、登録された商品の掲載審査を行い、形式審査をクリアした商品を掲載することとします（弊社は、この時点での実質審査は行いません）。
            </p>
            <p>
              ※価格設定の誤登録に関し、弊社では一切の責任を負いませんので、出品者は自己の責任において設定ください。
            </p>
            <p>
              ※出品者は、購入者からの購入申し込みまたは価格交渉があるまで、いつでも出品を撤回することができますが、購入申し込みがあった後は自ら撤回することはできません。
            </p>
          </div>
          <p>（２）購入者が出品した商品に対する購入申し込み</p>
          <p>（３）出品者と購入者間において、取引についての合意【売買契約成立】</p>
          <div className={css.attentionlist}>
            <p>
              ※この時点で出品者と購入者間において取引成立となり、売買契約が成立しますので、以降は、出品者と購入者はいずれも契約を撤回することはできません。
            </p>
            <p>
              ※売買契約成立後の商品の不発送、商品代金等の未入金、偽造品の送付等が発生した場合には、弊社の判断により、アカウントの停止・閉鎖・再登録の拒否等の対応を行うことがあります。
            </p>
          </div>
          <p>（４）出品者が弊社に商品を送付</p>
          <div className={css.attentionlist}>
            <p>※出品者は売買契約成立後、３営業日以内に商品を送付しなければなりません。</p>
            <p>
              ※出品者が取引成立後、３営業日以内に商品が弊社に届かなかった場合には、弊社は購入者に連絡し、購入者は契約の解約を出品者に伝えることができます。
            </p>
          </div>
          <p>（５）弊社が商品を受領・現物確認後、真贋保証</p>
          <div className={css.attentionlist}>
            <p>
              ※出品者が弊社に送付した商品を弊社が真贋確認をしたところ、真贋保証をすることができなかった場合には、売買契約は出品者の帰責性により購入者から解約されたものとみなします。
            </p>
          </div>
          <p>（６）弊社が購入者に商品確認及び商品代金について弊社に対する支払依頼</p>
          <div className={css.attentionlist}>
            <p>
              ※弊社が行う商品の真贋保証、購入者への商品確認連絡及び商品代金等の支払依頼をいたします（順不同となっております）。
            </p>
            <p>
              ※購入者は弊社からの支払依頼を受領後、弊社に対して３営業日以内に商品代金等を支払わなければなりません。
            </p>
            <p>
              ※購入者から、弊社に対し商品代金全額が支払われた時点で、購入者の買主としての支払い債務は原則として消滅いたします。
            </p>
            <p>
              ※弊社に対し、購入者からの入金が３営業日以内に確認できなかった場合、弊社は出品者に連絡し、出品者は契約の解約を購入者に伝えることができます。
            </p>
          </div>
          <p>
            （７）弊社において、購入者から弊社に対する商品代金等の入金確認完了後に商品を購入者に送付＜商品送付＞
          </p>
          <div className={css.attentionlist}>
            <p>
              ※弊社は入金確認後、原則として３営業日以内に商品を発送いたしますが、商品代金の支払いにおいて問題が発生した場合は除きます。
            </p>
          </div>
          <p>
            （８）弊社において、購入者からの商品代金等の入金確認完了後、（７）と同時期に出品者に商品代金を振込み＜商品代金お支払期日＞
          </p>
          <div className={css.attentionlist}>
            <p>
              ※弊社は購入者からの商品代金等の入金確認後、原則として３営業日以内に出品者の指定する口座に振り込みます。但し、商品の内容または購入者からの入金に問題が生じた場合等は除きます。
            </p>
          </div>
        </li>
        <li>
          真贋保証について
          <p>
            弊社は、出品者が商品を弊社に送付した販売時（より正確には弊社が商品を受領した時点）において「PCGS」「NGC」等による第三者鑑定機関の真正品であると判断したものを、真正品として保証いたします。そのため、仮に将来において科学的鑑定法の進歩、新資料の発見などにより真正性が覆された場合であっても一切の異議、返品は認められません。
          </p>
        </li>
        <li>
          本サービスご利用における注意点
          <p>
            弊社は、会員に事前に通告する事無く、本規約の内容やオンラインシステムの仕様を変更、提供の停止や中止する事があります。弊社はオンラインシステムにおけるサーバー上の不具合、故障、停止、利用出来ないなど予期せぬトラブルの全てにおいて弊社の故意または重過失に起因する場合を除き、一切の責任を負いません。
          </p>
        </li>
      </ul>

      <h3>第5条　サービス利用に必要な事項</h3>
      <ul className={css.contentNumberlist}>
        <li>
          サービス利用について
          <p>
            会員の皆様が本サービスを利用するには出品、購入それぞれに応じて、弊社が指定する必要事項、申告事項を満たし、かつ、本サービスの利用期間中、これを継続して維持する必要があります。
          </p>
        </li>
        <li>
          出品に必要な事項
          <p>（１）満年齢が18歳以上であること。</p>
          <div className={css.attentionlist}>
            <p>
              未成年者会員が出品を行う場合は、事前に保護者等の法定代理人全員の同意を得ていただく必要があります。
            </p>
          </div>
          <p>（２）【名称】ID（会員登録）を取得していること。</p>
          <p>（３）出品時に銀行口座の登録を行っていること。</p>
          <p>（４）日本語を理解し、読み書きが出来ること。</p>
          <p>
            （５）決済事業者の定めに従い、または弊社に身分証の提示をすることで本人確認手続を完了していること（犯罪による収益の移転防止に関する法律に基く。）
          </p>
          <p>（６）決済事業者の定める利用規約・条件に従うこと。</p>
        </li>
        <li>
          購入に必要な事項
          <p>（１）【名称】ID（会員登録）を取得していること。</p>
          <p>
            （２）弊社の各種サービスについて、決済事業者を利用して決済を行う場合は、決済事業者の利用に要した通信料金及びコンピューターや通信機器等の費用は、各会員が負担すること。決済事業者の利用方法については、当該決済事業者の定める利用規約・条件に従うこと。
          </p>
        </li>
      </ul>

      <h3>第6条　取扱い商品</h3>
      <ul className={css.contentNumberlist}>
        <li>
          弊社で取扱うコイン
          <p>
            弊社の指定する第三者鑑定機関（「PCGS」「NGC」等）が鑑定したスラブケースに封入されたコイン（以下「鑑定済みコイン」といいます。）もしくは、元箱、COAが付随するコインのみに限ります。
          </p>
        </li>
        <li>
          鑑定済みコインの取扱い基準
          <p>
            鑑定済みコインであっても弊社基準に満たない場合はお取扱いできない事がございます。取扱い基準は弊社の裁量によるものとし、その基準に関して、弊社は開示の義務を負いません。
          </p>
        </li>
        <li>
          未鑑定コイン取扱いについて
          <p>
            弊社の指定する第三者鑑定機関である「PCGS」「NGC」等による鑑定を受けていないコインは元箱、COAが付随するコインのみお取り扱いいたします。ただし、弊社基準に満たない場合はお取扱いできない事がございます。取扱い基準は弊社の裁量によるものとし、その基準に関して、弊社は開示の義務を負いません。
          </p>
        </li>
      </ul>

      <h3>第7条　会員登録及びアカウント情報</h3>
      <ul className={css.contentNumberlist}>
        <li>
          会員登録
          <p>
            会員登録は、必ず本人が行う必要があります。また、会員登録の際は、必ず正確な情報を申告しなければならないものとします。
          </p>
        </li>
        <li>
          複数会員登録の可否について
          <p>1個人1アカウントであり、複数の会員登録を行うことが出来ないものとします。</p>
        </li>
        <li>
          会員の取消、利用停止項目
          <p>
            弊社は、会員登録をされようとする者が以下各号のいずれかに該当する場合、会員登録の申請を承認しない、また会員登録の取消、利用停止を行うことがあります。
          </p>
          <p>（１）過去に本規約違反等により、弊社から利用停止等の処分を受けている場合</p>
          <p>（２）登録内容に正確ではない情報又は虚偽の情報が含まれていると判断した場合</p>
          <p>
            （３）弊社の運営、サービス提供若しくは他の会員の利用を妨害する又はそれらに支障をきたす行為を行った場合やそのおそれがあると弊社が合理的な理由に基づき判断した場合
          </p>
          <p>
            （４）暴力団、暴力団員、暴力団員でなくなったときから
            5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、若しくは特殊知能暴力集団、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）、テロリスト等日本政府若しくは外国政府が経済制裁の対象として指定する者に該当すること、又は暴力団員等と一定の関係を有すること（暴力団員等に対して資金を提供し若しくは便宜を供与するなどの関与をしていると認められること、暴力団員等を不当に利用していると認められること、又は、会員登録をされようとする方が法人の場合、暴力団員等がその経営を支配し若しくはその法人の経営に実質的に関与していると認められること、その他暴力団員等と社会的に非難されるべき関係を有していると認められること）（以下、上記のすべてを総称して「反社会的勢力」といいます。）が判明した場合
          </p>
          <p>（５）その他弊社が不適当であると合理的な理由に基づき判断する場合</p>
        </li>
        <li>
          反社会勢力でないことの誓約
          <p>
            会員等は、反社会的勢力のいずれにも該当しないこと、かつ将来にわたっても該当しないこと、及び、自ら又は第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をし又は暴力を用いる行為、風説を流布し、偽計を用い又は威力を用いて弊社の信用を毀損し又は弊社の業務を妨害する行為、その他これらに準ずる行為を行わないことを誓約するものとします。
          </p>
        </li>
        <li>
          会員情報の登録変更
          <p>
            会員は、登録内容に変更がある場合は、直ちに変更しなければならず、常に会員自身の正確な情報が登録されているように登録内容を管理及び修正する責任を負います。登録内容に変更があったにも関わらず、変更を行っていない場合、弊社は、登録内容に変更がないものとして取り扱うことができます。変更の届出があった場合でも、変更登録前に行われた取引や各種手続は、変更前の情報に依拠する場合があります。
          </p>
        </li>
        <li>
          会員情報登録変更の免責
          <p>
            会員が、会員登録や登録内容の変更をしたこと・しないことや、弊社が会員登録を承認しないことにより生じた損害に関しまして、弊社は一切責任を負わないものとします。
          </p>
        </li>
        <li>
          アカウント情報について
          <p>
            会員は、入力したメールアドレス、パスワード等のアカウント情報を自ら管理する責任を負います。会員は、アカウント情報を第三者に利用させることや、譲渡、売買、担保、貸与、賃貸その他形態を問わず管理または処分することはできません。
          </p>
        </li>
        <li>
          アカウント情報管理責任、弊社免責
          <p>
            会員は、アカウント情報が第三者に漏えいした場合又はそのおそれがある場合、速やかに弊社まで連絡するものとします。また、その際に弊社の指示がある場合にはこれに従うものとし、アカウント情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任は会員が負うものとし、弊社は一切責任を負わないものとします。ただし、弊社の故意または重過失に起因する場合を除きます。また、アカウント情報が不正に利用されたことにより弊社に損害が生じた場合、会員は当該損害を賠償しなければなりません。
          </p>
        </li>
      </ul>

      <h3>第8条　チャットの利用に関する遵守事項</h3>
      <ul className={css.contentNumberlist}>
        <li>
          会員は、チャット利用にあたって、開示する情報が不特定多数の者に開示される可能性があること、かつ、会員相互の個人的関係にもとづき送受信されるものであることを認識し、自己責任で発信するものとし、弊社は、会員がチャットで開示した一切の個人情報（自己のみならず第三者の情報も含む）の結果については一切責任を負いません。
        </li>
        <li>
          チャットにおける禁止事項
          <p>
            会員は、チャット利用をするにあたり、受信者の合意の有無にかかわらず、以下の内容を含むメッセージの送信または行為に関する発信をすることを禁止します。
          </p>
          <p>・商品に関する虚偽の情報内容</p>
          <p>
            ・氏名、メールアドレス、電話番号、住所、アカウントのID・PW、SNSのIDなど、個人を特定し連絡手段を得られる情報を含む内容
          </p>
          <p>・会員間が直接商品及び金員の授受を可能とするような内容</p>
          <p>・詐欺・脅迫・暴力的表現、差別的表現、性的表現等、公序良俗に反する内容</p>
          <p>・非礼的表現、不快な表現等、社会的に本サービスの利用に際し、不適切な内容</p>
          <p>・他の会員や弊社に不利益を与える内容</p>
          <p>・他の会員や弊社に対する誹謗中傷、名誉や信用を棄損し、侮辱する内容</p>
          <p>・他の会員の意思に反し、執拗または大量のメッセージ送信等迷惑をかける行為</p>
          <p>・チャット内で開示された内容を相手方の同意なく第三者に開示する行為</p>
          <p>
            ・本サービスと無関係な内容、個人的な内容等本サービスが予定している利用目的と異なる目的でのチャットの継続的使用（私物化等）
          </p>
          <p>・犯罪を構成し、またはこれに準ずる内容を含むメッセージの発信または行為</p>
          <p>
            ・選挙運動・宗教活動・恋愛活動・交際活動・広告活動・他のサービスへの勧誘等のこれらに類する行為
          </p>
          <p>・法令に違反する内容及び行為</p>
          <p>
            ・その他、本サービスを含む弊社のサービス提供を妨げまたはその信用を棄損する内容を含むメッセージの発信または行為
          </p>
        </li>
        <li>
          弊社は、チャットを完全性・有用性・正確性・信頼性等に不安定な瑕疵を含みうるものとして提供しており、弊社はチャットの提供について、その完全性・有用性・正確性・信頼性等に関する如何なる保証もいたしません。
        </li>
        <li>
          会員は、出品者及び購入者間で取り交わされるチャットメッセージの内容について、弊社が売買契約の正確な把握、本サービスの品質向上等のために閲覧、分析することについて予め同意するものとします。
        </li>
        <li>
          会員間において、直接商品及び金員を授受する内容を含むチャットを行ったことにより、弊社に損害が生じた場合、会員は弊社に生じた一切の損害を賠償するものとします。
        </li>
      </ul>

      <h3>第9条　弊社の免責事項</h3>
      <ul className={css.contentNumberlist}>
        <li>
          会員間の取引に関する免責
          <p>
            本サービスに基づく取引は、あくまで会員間の直接取引であって、弊社は、会員間の取引内容には一切関与しません。したがって、出品者または購入者は、弊社が会員間の取引または商品等に関していかなる責任も負わないことにあらかじめ同意するものとします。ただし、弊社の故意または重過失に起因する場合を除きます。
          </p>
        </li>
        <li>
          出品者及び購入者の行為に関して
          <p>（１）出品者の行為</p>
          <div className={css.attentionlist}>
            <p>
              弊社は、本サービスにおいて出品者が出品した商品について、第４条第２項を除き一切保証しません。したがって、購入者は、出品者の商品の未発送、異なる商品の出品、真贋保証のできない商品の出品等出品者の行為により取引が終了した場合であっても、弊社がいかなる責任を負わないことにあらかじめ同意するものとします。
            </p>
          </div>
          <p>（２）購入者の行為</p>
          <div className={css.attentionlist}>
            <p>
              弊社は、本サービスにおいて会員が購入した商品等の代金の支払能力があるか否かに関して一切保証しません。したがって、出品者は、購入者の商品代金の未払いにより取引が終了した場合であっても、弊社がいかなる責任も負わないことにあらかじめ同意するものとします。
            </p>
          </div>
          <p>（３）チャットにおける双方の行為</p>
          <div className={css.attentionlist}>
            <p>
              弊社は、本サービスにおいてチャット機能におけるやり取りを含む会員間のやり取りに関しては一切関与せず、その保証をしません。したがって、会員は会員間において法的紛争を含むトラブルが発生した場合であっても、弊社がいかなる責任も負わないことにあらかじめ同意するものとします。
            </p>
          </div>
        </li>
        <li>
          会員情報の開示義務
          <p>
            弊社は、法令に基づく場合を除き、取引の相手方の情報を出品者または購入者に開示する義務を負いません。
          </p>
        </li>
        <li>
          出品者・購入者都合によるキャンセルに伴う会員間トラブルに関して
          <p>
            出品者又は購入者都合での取引がキャンセルとなった場合、キャンセルを行った出品者または購入者は、弊社に対し弊社の定めるキャンセル料金を支払わなければなりません。また、キャンセルを行った出品者又は購入者が、相手方から独自に別途損害賠償を求められた場合、弊社は当事者間の一切の仲介、仲裁等の義務を負いません。
          </p>
        </li>
        <li>
          会員の出品に伴って生じる損害について
          <p>
            会員の出品等によって、会員及び第三者に生じる損害につき、弊社は一切責任を負わないものとします。ただし、弊社の故意または重過失に起因する場合を除きます。
          </p>
        </li>
        <li>
          運送中の事故に関する免責
          <p>
            出品または購入に際し、運送中の物損、紛失事故の一切の責任は、商品を送付する出品者または商品を受領する購入者が指定した運送会社が加入する運送保険を適用し、弊社は損害賠償の一切の義務を負わないものとします。
          </p>
        </li>
        <li>
          お預かり商品の補償について
          <p>
            お預かり期間中（運送中の期間を含まない）に弊社の過失なく商品の破損、変色、紛失などの異常が認められた場合は、お客様負担にてご返却させて頂きます。
            お預かり期間中に弊社の過失による商品の破損、変色、紛失などの異常は市場価格を上限として補償させて頂きます。
          </p>
        </li>
        <li>
          盗難に関する免責
          <p>
            弊社は盗難防止策を可能な限り講じますが、商品の盗難があった場合は、警察・司法の判断に任せることとし、弊社は賠償責任を負わないこととします。
          </p>
        </li>
        <li>
          火災、天災に関する免責
          <p>
            火災、天災、事変等の不可抗力、不測の事態による商品の破損や紛失は弊社で責任を負わないものとします。
          </p>
        </li>
        <li>
          決済事業者のサービスに関する免責
          <p>
            取引の際に決済事業者のサービスの不備等に起因し、会員及び第三者に生じる損害につき、弊社は一切責任を負わないものとします。
          </p>
        </li>
      </ul>

      <h3>第10条　情報の閲覧</h3>
      <p>
        弊社は、本サービスのチャット機能において、会員間で行う送受信の内容、情報等について、犯罪が生じている疑いがある場合等の正当理由があり、必要であると判断した場合、または会員間の承諾がある場合は、閲覧することができるものとし、会員は弊社に対し事前に承諾するものとします。
      </p>

      <h3>第11条　会員の退会</h3>
      <ul className={css.contentNumberlist}>
        <p>退会について</p>
        <p>
          会員は、弊社に退会を希望する旨を申し出ることにより退会することができます。但し、取引の決済や商品の郵送等の取引の手続が未完のものがある場合は退会することができず、会員は、一連の未完の取引を本規約に従って速やかに、かつ円滑に進め完了させた後、弊社に改めて退会の申し出を行わなければなりません。一度、退会すると全てのサービスは利用できなくなります。
        </p>
      </ul>

      <h3>第12条　会員の遵守事項</h3>
      <p>
        会員が以下の各項に記載された遵守事項に違反し、弊社に損害を与えた場合には、弊社は当該会員に対し、損害賠償を請求させていただくことがあります。
      </p>
      <ul className={css.contentNumberlist}>
        <li>
          他者への配慮
          <p>
            他の会員もしくはその他の第三者を差別もしくは誹謗中傷し、又は他者の名誉もしくは信用を毀損する行為を行ってはいけません。
          </p>
        </li>
        <li>
          規約履行
          <p>
            本サービスの利用を契機として会員間で契約が成立した場合には、誠実に義務を履行しなければなりません。
          </p>
        </li>
        <li>
          法令遵守
          <p>
            会員は、本サービスを利用する上で遵守すべき古物営業法、特定商取引に関する法律、その他の法令を遵守しなければなりません。
          </p>
        </li>
        <li>
          商品情報記載における遵守事項
          <p>
            商品の情報（出品商品の商品説明文）を記載する際、以下の事項をお守り頂けない場合は商品削除やアカウント利用停止などの対象となります。
          </p>
          <p>（１）商品に関して虚偽の情報を記載しない。</p>
          <p>
            （２）氏名、メールアドレス、電話番号、住所、SNSのIDなど、個人を特定し連絡手段を得られる情報を記載しない。
          </p>
          <p>（３）他ユーザーまたは他サービスからの商品説明文を無断でコピー・転載をしない。</p>
          <p>
            （４）【名称】以外での決済方法を促す文書を記載しない。（ユーザー同士が直接商品及び金員の授受をするような内容の記載禁止。）
          </p>
          <p>（５） 宗教活動、勧誘を促すような文章を記載しない。</p>
          <p>（６） その他、弊社が不適切と判断した文書を記載しない。</p>
        </li>
        <li>
          チャット使用における遵守事項
          <p>
            チャットの使用に際し、第8条で定める事項をお守りいただけない場合は、チャット機能の停止やアカウント利用停止などの対象となります。
          </p>
        </li>
      </ul>

      <h3>第13条 本サービスの中断・終了及び変更</h3>
      <ul className={css.contentNumberlist}>
        <li>
          本サービスの中断
          <p>
            弊社は、以下の各号のいずれかに該当する場合には、会員に事前に通知することなく一時的に本サービスの全部又は一部を中断する事ができるものとします。その際、会員に損害が発生した場合であっても、弊社は一切の責任を負わないものとします。ただし、弊社の故意または重過失に起因する場合を除きます。
          </p>
          <p>
            （１）サーバー、通信回線、その他の設備の故障、障害の発生又はその他の理由により本サービスの提供ができなくなった場合
          </p>
          <p>
            （２）定期的な又は緊急のシステム（サーバー、通信回線や電源、それらを収容する建築物などを含む）の保守、点検、修理、変更を行う場合
          </p>
          <p>（３）火災、停電等により本サービスの提供ができなくなった場合</p>
          <p>
            （４）疫病、感染症、地震、噴火、洪水、津波等の天災により本サービスの提供ができなくなった場合
          </p>
          <p>
            （５）戦争、変乱、暴動、騒乱、労働争議等その他不可抗力により本サービスの提供ができなくなった場合
          </p>
          <p>（６）法令又はこれに基づく措置により本サービスの提供ができなくなった場合</p>
          <p>
            （７）その他、運用上又は技術上弊社が本サービスの一時的な中断が必要であると判断した場合
          </p>
        </li>
        <li>
          本サービスの終了及び変更
          <p>
            弊社は、任意の理由により、いつでも本サービスの全部または一部を変更ないし終了できるものとします。ただし、本サービスの全部または重要部分の変更ないし終了については、当該変更ないし終了の相当期間前に通知するものとします。
          </p>
          <p>
            弊社は、本サービスの終了及び変更による損害について、会員及び第三者に対して一切責任を負わないものとします。
          </p>
        </li>
      </ul>

      <h3>第14条秘密情報の取り扱い</h3>
      <p>
        会員は、本サービスに関連して弊社が会員に対して開示した非公知の情報のうち、弊社が秘密に取り扱うことを求めたものについては、書面による弊社の事前の承諾がある場合を除き、第三者に開示せず秘密に取り扱うものとします。
      </p>

      <h3>第15条 一般条項</h3>
      <ul className={css.contentNumberlist}>
        <li>
          通知又は連絡
          <p>
            本サービスに関する弊社から会員への通知又は連絡は、弊社が運営するウェブサイト内の適宜の場所への掲示その他、弊社が適当と判断する方法により行なうものとします。弊社は、個々の会員に通知及び連絡をする必要があると判断した際、登録された電子メールアドレス、住所又は電話番号に対し、電子メール、郵便、電話等を用いて通知及び連絡を行うことがあります。弊社から通知または連絡を行ったにもかかわらず、不着であったり遅延したりといったことによって生じる損害について、弊社は一切の責任を負いません。
          </p>
        </li>
        <li>
          譲渡禁止
          <p>
            会員は、弊社の書面による事前の承諾なく、本規約に基づく契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、貸与、その他の処分をすることはできません。
          </p>
        </li>
        <li>
          定めのない事項等
          <p>
            本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、会員は、弊社の定めるところに従うものとします。これにより解決しない場合には、弊社及び会員は、信義誠実の原則に従って協議の上速やかに解決を図るものとします。
          </p>
        </li>
        <li>
          準拠法及び裁判管轄
          <p>
            本規約は、日本法に基づき解釈されるものとし、会員と弊社の間で生じた紛争については、その内容に応じて横浜簡易裁判所又は横浜地方裁判所を第一審の専属的合意管轄裁判所とします。
            ただし、会員相互の紛争については、民事訴訟法等適用となる法令に準ずるものとします。
          </p>
        </li>
      </ul>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
